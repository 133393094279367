import React from "react"
import styles from "./projects.module.css"
import styled from './underline.module.css'
import ProjectTile from "./project-tile.js"
import staticdata from "./staticdata.json"

const Projects = () => (
  <>
    <section id="projects">

      <div className={styles.projectWrapper}>
        <h2 className={styled.underlineWhite}>Projects</h2>
        <p className={styles.subHeading}>Take a look at what I've been up to lately</p>

        <div className={styles.gridWrapperProjects}>

          {staticdata.project.map(tile => (
            <ProjectTile
              link={tile.link}
              thumbnail={tile.thumbnail}
              alt={tile.alt}
              title={tile.title}
              description={tile.description}
            />
          ))}



        </div>

      </div>


    </section>
  </>
)

export default Projects
