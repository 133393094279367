import React from "react"
import styles from "./contact.module.css"
import styled from './underline.module.css'


const Contact = () => (
  <>
    <section>

      {/* <div className={styles.reachOutWrapper}>
        <h3>I'm available</h3>
        <br></br>
        <p>I'm currently taking on new projects and would love to bring yours to life.
          <br></br>
          <a className={styles.reachOut} href="/#contact">Let's chat <i style={{
            paddingLeft: "1rem"
          }} class="fas fa-chevron-down"></i></a></p>
      </div> */}

      <div id="contact" className={styles.formWrapper}>

        <form name="contact" method="POST" data-netlify="true">

          <h2 className={styled.underlineBlack}>Contact</h2>

          <p className={styles.subHeading}>Send me a message</p>

          <input type="hidden" name="form-name" value="contact" />

          <label for="name">
            <input type="text" name="name" placeholder="Name" required />
          </label>

          <label for="email">
            <input type="email" name="email" placeholder="Email" required />
          </label>

          <label for="message"><textarea name="message" placeholder="Tell me about your project." />
          </label>

          <button type="submit" className={styles.submitButton} > Send</button>

        </form>

      </div>

    </section>
  </>
)

export default Contact
