import React from "react"
import styles from './about.module.css'
import styled from './underline.module.css'
// import ski from './skier.svg'
// import browsers from './browsers-icon1.svg'
// import layout from './layout-icon1.svg'
// import intuitive from './intuitive-icon1.svg'

const About = () => (
  <>
    <section id="about" className={styles.padTopLarge}>
      <h2 className={styled.underlineBlack}>About</h2>

      <div className={styles.aboutDesktopGrid}>

        <div className={styles.padTopHeading}>
          <p className={styles.aboutBio}>I'm Cameron King, a full stack web developer.</p>
          <p className={styles.aboutBio}>Recently graduated from BrainStation's web development bootcamp, I'm ready to start the next chapter in my career.</p>
          <p className={styles.aboutBio}>My passion for learning, creating, and experimenting is channeled through my love for web development. My love for the outdoors manages to work it's way into just about everything I do.</p>

        </div>

        {/* <ul className={styles.padTopSmallMarginBottom}>

          <li>
            <img className={styles.icon} src={ski} alt="stuff" height=""></img>
            <div className={styles.infoWrapper}>
              <h3 className={styles.infoTag}>Fast</h3>
              <p className={styles.infoDescription}>We feel the need for speed. It's essential to ensure quick load times and high performance.</p>
            </div>
          </li>

          <li>
            <img className={styles.icon} src={browsers} alt="stuff" height=""></img>
            <div className={styles.infoWrapper}>
              <h3 className={styles.infoTag}>Responsive</h3>
              <p className={styles.infoDescription}>All our websites are designed for mobile sizes and built upwards to ensure a reliable experience across any device.</p>
            </div>
          </li>

          <li>
            <img className={styles.icon} src={layout} alt="stuff" height=""></img>
            <div className={styles.infoWrapper}>
              <h3 className={styles.infoTag}>Clean</h3>
              <p className={styles.infoDescription}>We keep web pages clean and sleek looking so that the focus is always on the content and message.</p>
            </div>
          </li>

          <li>
            <img className={styles.icon} src={intuitive} alt="stuff" height=""></img>
            <div className={styles.infoWrapper}>
              <h3 className={styles.infoTag}>Intuitive</h3>
              <p className={styles.infoDescription}>Simple, easy to use UI and layouts create a seemless and easy to navigate user experience.</p>
            </div>
          </li>

        </ul> */}

      </div>

    </section>

  </>
)

export default About
