import React from "react"
import styles from "./projects.module.css"


const ProjectTile = props => (
  <>

    <div className={styles.projectTile}>
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <img className={styles.thumbnail} src={props.thumbnail} alt={props.alt}>
        </img>
      </a>
      <h3>{props.title}</h3>
      <p>
        {props.description}
      </p>
    </div>

  </>
)

export default ProjectTile
