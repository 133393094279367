import React from "react"
import styles from "./footer.module.css"

const Footer = () => (
  <>
    <footer>

      <div className={styles.footerItems}>

        <a id="home-link" className={styles.returnTopButton} href="/"><i class="fas fa-chevron-up"></i><br></br> Top</a>

      </div>

      <div className={styles.watermark}>
        King Country Web Development <br></br> &copy;2020
      </div>

    </footer>

  </>
)

export default Footer
