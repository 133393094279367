import React from 'react';
import Banner from './banner.js'
import About from './about.js'
import Header from './header.js'
import Projects from './projects.js'
import Contact from './contact.js'
import Footer from './footer.js'


function App() {
  return (
    <div className="App">

      <Banner />

      <Header />

      <About />

      <Projects />

      <Contact />

      <Footer />

    </div>
  );
}

export default App;
