import React, {useEffect} from 'react';
import './header.css'
import logo from './king-country-site-logo.svg'
import MenuIcon from './menu-icon.js'

const Header = () => {

  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {

    const header = document.querySelector('header');
    const headerPosition = header.getBoundingClientRect();
    const headerY = headerPosition.top;

    const offset = window.scrollY - headerY;

      if (offset > 850) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })
  let headerClasses = ['topHeader'];
    if (scrolled) {
      headerClasses.push(' scrolled');
    }

  return (
    <>
      <header className={headerClasses.join("")}>
        <a href="/">
          <img src={logo} alt="Logo"></img>
        </a>

        <MenuIcon />
      </header>
    </>
);
}

export default Header;
