import React from 'react';
import styles from './banner.module.css'
// eslint-disable-next-line
import mtns from './mtns-bg1.svg'


const Banner = () => {

    return (

      <div id="top" className={styles.bannerBackground}>

        <div className={styles.snowflakes} aria-hidden="true">
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
          <div className={styles.snowflake}>
            .
          </div>
        </div>

        <div className={styles.bannerTitle}>

          <h1>King Country</h1>
          <h2>Web Development</h2>

        </div>


        <a className={styles.bannerButton} href="index.html#about">View my work<i class="fas fa-chevron-down"></i>
        </a>


        <div className={styles.mountains}>



        </div>

      </div>

    );
  }


export default Banner;
