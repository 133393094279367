import React, {useState} from 'react';
import styled from 'styled-components'


const StyledMenuIcon = styled.div`
  margin: 0;
  padding: .5rem 1rem .5rem 1rem;
  width: 2rem;
  position: absolute;
  right: 0;
  transform: translateY(-1rem);

  &:hover {
    cursor: pointer;
  }

  div {
    margin: 0;
    width: 2rem;
    height: .25rem;
    background-color: #FFFFFF;
    transform-origin: 1px;
    transition: all 0.3s linear;
    z-index: ;

    &:nth-child(1) {
      margin-bottom: .25rem;
      transform: ${({open}) => open ? 'rotate(32.5deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      margin-bottom: .25rem;
      transform: ${({open}) =>open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({open}) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-32.5deg)' : 'rotate(0)'};
    }
}
`

const Nav = styled.nav`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  right: 0;
  overflow-y: hidden;
  transform: ${({open}) => open ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 1s ease-out;
  color: #FFFFFF;
  background-color: rgba(18, 17, 58, 1);
  z-index: 200;

  ul {
    padding: 5rem 0;
    overflow: hidden;
  }

  li {
    display: flex;
    flex-wrap: nowrap;
  }

  a {

    margin: 0 auto;
    padding: 2rem 5rem;
    min-width: 7.5rem;
    display: block;
    font-size: 1.25rem;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
  }
  a:hover {
    background-color: rgba(250, 250, 250, .5);
  }
`

const StyledExitIcon = styled.div`
  padding: 1rem;
  width: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: ;

  &:hover {
    cursor: pointer;
  }


  div {
    margin: 0;
    width: 2rem;
    height: .25rem;

    background-color: #FFFFFF;
    transform-origin: 1px;
    transition: all 0.3s linear;
    z-index: ;

    &:nth-child(1) {
      margin-bottom: .25rem;
      transform: ${({open}) => open ? 'rotate(32.5deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      margin-bottom: .25rem;
      transform: ${({open}) =>open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({open}) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-32.5deg)' : 'rotate(0)'};
    }
}
`

const MenuIcon = () => {

  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledMenuIcon open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledMenuIcon>

      <Nav open={open}>
        <StyledExitIcon open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledExitIcon>
        <ul>
          <li><a onClick={() => setOpen(!open)} id="top-link" href="/">Home</a></li>

          <li><a onClick={() => setOpen(!open)} id="about-link" href="index.html#about">About</a></li>

          <li><a onClick={() => setOpen(!open)} id="projects-link" href="#projects">Projects</a></li>

          <li><a onClick={() => setOpen(!open)} id="contact-link" href="#contact">Contact</a></li>
        </ul>
      </Nav>
    </>
);
}

export default MenuIcon;
